<template>
  <!----------------------------------------- LABOUR COMPONENT  ------------------------------->
  <div>
    <!--------------------Remove & Refit ----------------------->
    <div class="mb-4 "
      v-if="selectedTab.type == ItemCategoryTypes.RR || selectedViewType != viewTypes.tabbedView">
      <quote-builder-labour-headings-component name="Remove & Refit"
        :is-full-width="isFullWidth"
        :is-quote-hour="isQuoteTypeHour"
        :total-value="rrTotal"
        :is-ntar="isNtar" />
      <div class="rows">
        <draggable @change="itemMoved($event, ItemCategoryTypes.RR)"
          v-model="innerValue.labours"
          @over.prevent
          @enter.prevent
          v-bind="{'disabled': !isRowDraggable, ghostClass: 'draggable-ghost', dragClass: 'draggable-drag'}"
          :clone="$event => cloneHandler($event, ItemCategoryTypes.RR)"
          :group="{ name: ItemCategoryTypes.RR, pull: 'clone', put: true }"
          handle=".item-drag-handle"
          style="min-height:10px">
          <div v-for="item in innerValue.labours"
            :key="item.quoteItemId + ItemCategoryTypes.RR">
            <quote-builder-labour-item :read-only-view="readOnlyView"
              v-if="item.itemType == ItemCategoryTypes.RR"
              :vehicle="vehicle"
              :inner-value="innerValue"
              :items-assessments="itemsAssessments"
              :is-quote-type-hour="isQuoteTypeHour"
              :is-full-width="isFullWidth"
              :item="item"
              :edit-item-id="editItemObj.id"
              :fully-expanded="editItemObj.isFullyExpanded"
              :is-ntar="isNtar"
              @updateValue="updateValue"
              @focusNextRow="id => $emit('focusNextRow', id)"
              @handlePresentAs="itemId => $emit('handlePresentAs', itemId)"
              @itemRowClicked="(value, event, expand, close) => $emit('handleItemRowClick',value, event, expand, close)" />
          </div>
        </draggable>
      </div>
      <div>
        <div class="button has-text-primary"
          @click="addBlankItem($event, ItemCategoryTypes.RR)">
          + Add Item
        </div>
      </div>
    </div>
    <!-----------------------REPAIR ----------------------->
    <div class="mb-4"
      v-if="selectedTab.type == ItemCategoryTypes.REP || selectedViewType != viewTypes.tabbedView">
      <quote-builder-labour-headings-component name="Repair"
        :is-full-width="isFullWidth"
        :is-quote-hour="isQuoteTypeHour"
        :total-value="repairTotal"
        :is-ntar="isNtar"
        :show-loading="isNtar" />
      <div class="rows">
        <draggable @change="itemMoved($event, ItemCategoryTypes.REP)"
          v-model="innerValue.labours"
          @over.prevent
          @enter.prevent
          :clone="$event => cloneHandler($event, ItemCategoryTypes.REP)"
          :group="{ name: ItemCategoryTypes.REP, pull: 'clone', put: true }"
          v-bind="{'disabled': !isRowDraggable, chosen: 'chosen', ghostClass: 'draggable-ghost', dragClass: 'draggable-drag'}"
          handle=".item-drag-handle"
          style="min-height:10px">
          <div v-for="item in innerValue.labours"
            :key="item.quoteItemId + ItemCategoryTypes.REP">
            <quote-builder-labour-item :inner-value="innerValue"
              v-if="item.itemType == ItemCategoryTypes.REP"
              :read-only-view="readOnlyView"
              :items-assessments="itemsAssessments"
              :vehicle="vehicle"
              :is-full-width="isFullWidth"
              :is-quote-type-hour="isQuoteTypeHour"
              :item="item"
              :edit-item-id="editItemObj.id"
              :fully-expanded="editItemObj.isFullyExpanded"
              :show-loading="isNtar"
              :is-ntar="isNtar"
              @updateValue="updateValue"
              @focusNextRow="id => $emit('focusNextRow', id)"
              @handlePresentAs="itemId => $emit('handlePresentAs', itemId)"
              @itemRowClicked="(value, event, expand, close) => $emit('handleItemRowClick',value, event, expand, close)" />
          </div>
        </draggable>
      </div>
      <div>
        <div class="button has-text-primary"
          @click="addBlankItem($event, ItemCategoryTypes.REP)">
          + Add Item
        </div>
      </div>
    </div>
    <!----------------------PAINT ----------------------->
    <div class="mb-4"
      v-if="selectedTab.type == ItemCategoryTypes.PAINT || selectedViewType != viewTypes.tabbedView">
      <quote-builder-labour-headings-component name="Paint"
        :is-quote-hour="isQuoteTypeHour"
        :is-paint-item="true"
        :total-value="paintTotal"
        :is-full-width="isFullWidth"
        :is-ntar="isNtar" />

      <div class="rows">
        <draggable @change="itemMoved($event, ItemCategoryTypes.PAINT)"
          v-model="innerValue.labours"
          @over.prevent
          @enter.prevent
          :clone="$event => cloneHandler($event, ItemCategoryTypes.PAINT)"
          :group="{ name: ItemCategoryTypes.PAINT, pull: 'clone', put: true }"
          v-bind="{'disabled': !isRowDraggable, chosen: 'chosen', ghostClass: 'draggable-ghost', dragClass: 'draggable-drag'}"
          handle=".item-drag-handle"
          style="min-height:10px">
          <div v-for="item in innerValue.labours"
            :key="item.quoteItemId">
            <quote-builder-labour-item v-if="item.itemType == ItemCategoryTypes.PAINT"
              :inner-value="innerValue"
              :is-quote-type-hour="isQuoteTypeHour"
              :is-full-width="isFullWidth"
              :vehicle="vehicle"
              :items-assessments="itemsAssessments"
              :read-only-view="readOnlyView"
              :item="item"
              :is-paint="true"
              :edit-item-id="editItemObj.id"
              :fully-expanded="editItemObj.isFullyExpanded"
              :is-ntar="isNtar"
              @updateValue="updateValue"
              @focusNextRow="id => $emit('focusNextRow', id)"
              @handlePresentAs="itemId => $emit('handlePresentAs', itemId)"
              @itemRowClicked="(value, event, expand, close) => $emit('handleItemRowClick',value, event, expand, close)" />
          </div>
        </draggable>
      </div>
      <div>
        <div class="button has-text-primary"
          @click="addBlankItem($event, ItemCategoryTypes.PAINT)">
          + Add Item
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import QuoteBuilderLabourHeadingsComponent from './QuoteBuilderLabourHeadingsComponent.vue'
import QuoteBuilderLabourItem from './QuoteBuilderLabourItem.vue'
import { ItemCategoryTypes, LabourTimeTypes, PaintGroupTypes, QuotingMethodTypes, QuoteItemRevTypes } from '@/enums'
import draggable from 'vuedraggable'
import { QuoteItemModel } from '@/classes/viewmodels'
import { roundAwayFromZero } from '@/components/utils/AccountingFunctions'
import { QuoteTotalsMethodMixin, QuoteAssessmentMixin, QuoteItemValidationMixin, QuoteLabourMixin } from '../../mixins'
import { cloneDeep } from 'lodash'

export default {
  name: 'QuoteBuilderLabourComponent',
  components: {
    QuoteBuilderLabourHeadingsComponent,
    QuoteBuilderLabourItem,
    draggable
  },
  mixins: [QuoteTotalsMethodMixin, QuoteAssessmentMixin, QuoteItemValidationMixin, QuoteLabourMixin],
  props: {
    innerValue: {
      type: Object,
      required: true
    },
    isFullWidth: {
      type: Boolean,
      required: true
    },
    editItemObj: {
      type: Object
    },
    selectedTab: {
      type: Object
    },
    selectedViewType: {
      type: String
    },
    viewTypes: {
      type: Object
    },
    vehicle: {
      type: Object
    },
    readOnlyView: {
      type: Boolean
    },
    nextLineNumber: {
      type: Number,
      default: 0
    }
  },
  computed: {
    ItemCategoryTypes() {
      return ItemCategoryTypes
    },

    LabourTimeTypes() {
      return LabourTimeTypes
    },
    PaintGroupTypes() {
      return PaintGroupTypes
    },
    isRowDraggable() {
      return !this.innerValue.readOnly
    },
    QuotingMethodTypes() {
      return QuotingMethodTypes
    },
    isQuoteTypeHour() {
      return this.innerValue.quotingMethod === QuotingMethodTypes.Hour
    },
    rrTotal() {
      return this.calculateItemCategoryTotal(this.innerValue.quotingMethod, cloneDeep(this.innerValue.labours), ItemCategoryTypes.RR)
    },
    repairTotal() {
      return this.calculateItemCategoryTotal(this.innerValue.quotingMethod, cloneDeep(this.innerValue.labours), ItemCategoryTypes.REP)
    },
    paintTotal() {
      return this.calculateItemCategoryTotal(this.innerValue.quotingMethod, cloneDeep(this.innerValue.labours), ItemCategoryTypes.PAINT)
    },
    isNtar() {
      return (
        this.innerValue.labourType === LabourTimeTypes.NTAR ||
        this.innerValue.labourType === LabourTimeTypes.LTAR ||
        this.innerValue.labourType === LabourTimeTypes.eMTA
      )
    },
    itemsAssessments() {
      return this.getItemsAssessments(cloneDeep(this.innerValue.labours), cloneDeep(this.innerValue.quoteAssessments))
    }
  },
  mounted() {
    this.$v.entity.labours.$touch()
  },
  methods: {
    cloneHandler(item, type) {
      // change the quoteItemId to a new guid so we can find it later and change to correct type on drop
      // TODO: looks like this doesn't work, leave it as is
      // item.quoteItemId = Guid.newGuid()
      return item
    },
    itemMoved(event, type) {
      // if an item was dragged from one list to another, i.e from RR to RWA, Parts to RR etc.
      if (event?.added) {
        const item = event.added.element
        this.handleItemAdded(item, type, event.added.newIndex)
      }
      let sortNo = Math.min(...this.innerValue.labours.map((i) => i.sortNo))
      this.innerValue.labours.forEach((item) => {
        item.sortNo = sortNo
        sortNo++
      })
    },
    handleItemAdded(item, type, index) {
      const newItem = this.generateItem(item.itemNo, item.itemDesc, item.hourValue, item.rate, type)
      // replace the item with the new item
      this.innerValue.labours.splice(index, 1, newItem)
      this.$emit('onItemCopied', newItem)
    },
    addBlankItem(event, type) {
      // this is to prevent outside click event from firing, which collpases all the items
      event.stopPropagation()
      let hour = 0
      let rate = 0
      // const type = ItemCategoryTypes.RR
      switch (type) {
        case ItemCategoryTypes.RR:
          rate = this.isNtar
            ? this.innerValue.shopRate
            : this.getLabourRate(ItemCategoryTypes.RR, this.innerValue.rates, this.innerValue.shopRate, this.innerValue.quoteId).rate
          break
        case ItemCategoryTypes.RWA:
          rate = this.isNtar
            ? this.innerValue.shopRate
            : this.getLabourRate(ItemCategoryTypes.RWA, this.innerValue.rates, this.innerValue.shopRate, this.innerValue.quoteId).rate
          break
        case ItemCategoryTypes.REP:
          rate = this.isNtar
            ? this.innerValue.shopRate
            : this.getLabourRate(ItemCategoryTypes.REP, this.innerValue.rates, this.innerValue.shopRate, this.innerValue.quoteId).rate
          break
        case ItemCategoryTypes.PAINT:
          rate = this.isNtar
            ? this.innerValue.shopRate
            : this.getLabourRate(this.vehicle.paintGroup, this.innerValue.rates, this.innerValue.shopRate, this.innerValue.quoteId).rate
          break
        default:
      }
      const newItem = this.generateItem('', '', hour, rate, type)

      this.innerValue.labours.splice(this.innerValue.labours.length, 1, newItem)
      this.innerValue.lines = this.nextLineNumber + 1
      this.$toast.open({
        message: 'Item added',
        type: 'is-success',
        position: 'is-bottom',
        queue: false
      })

      this.$nextTick(() => {
        this.$emit('newItemAdded', newItem.quoteItemId)
      })
    },
    getLabourRate(code, rates, shopRate, quoteId) {
      if (!rates) {
        return {}
      }
      const isPaintType = Object.values(PaintGroupTypes).some((p) => p === code)
      const codeType = isPaintType ? ItemCategoryTypes.PAINT : code
      let rate = this.isNtar ? rates[0] : rates.find((i) => i.labourCodeId === code)
      if (!rate) {
        const labourType = this.$labourTypes.find((t) => t.labourTypeCode === codeType)
        const newRate = {
          quoteId: quoteId,
          labourCodeId: code,
          labourTypeId: labourType.labourTypeId,
          rate: this.isNtar ? shopRate : 0,
          modifiedBy: '',
          modifiedDate: null,
          createdBy: '',
          createdDate: null,
          isNew: true,
          quoteVersion: 0,
          deleted: false
        }
        rates.push(newRate)
        rate = newRate
      }
      return rate
    },
    generateItem(itemNo, description, hourValue, rate, type) {
      let newItem = new QuoteItemModel(this.innerValue.quoteId, itemNo, description, type)
      newItem.lineNumber = this.nextLineNumber
      newItem.itemNo = itemNo ? itemNo : this.$filters.pad(newItem.lineNumber, 4)
      newItem.hourValue = this.innerValue.quotingMethod === QuotingMethodTypes.Hour ? hourValue : roundAwayFromZero(hourValue * rate)
      newItem.rate = rate
      newItem.dollarValue = roundAwayFromZero(hourValue * rate)
      newItem.opgCode = this.vehicle.paintGroup
      newItem.sortNo = this.innerValue.labours.length ? Math.max(...this.innerValue.labours.map((i) => i.sortNo)) + 1 : 1
      return newItem
    },
    updateValue(item) {
      if (this.innerValue.quotingMethod === QuotingMethodTypes.Dollar) {
        item.dollarValue = item.hourValue
      } else {
        item.dollarValue = roundAwayFromZero(item.hourValue * item.rate)
        if (this.getLabourRateEx(item).rate === item.rate) {
          item.rev = QuoteItemRevTypes.Normal
        } else {
          item.rev = QuoteItemRevTypes.SpecRate
        }
      }

      this.addUpdateRepairPaintLoading(item)
      this.updateTotal()
    },
    async addUpdateRepairPaintLoading(item, isManualLoading = false) {
      let repairItem =
        item.itemType === ItemCategoryTypes.REP
          ? this.innerValue.labours.find((i) => i.quoteItemId === item.quoteItemId && !i.deleted)
          : this.innerValue.labours.find((i) => i.itemType === ItemCategoryTypes.REP && i.itemNo === item.itemNo && i.itemDesc === item.itemDesc && !i.deleted)
      if (!this.isNtar || !repairItem || !this.ntarLoadingItems || !this.ntarOtherLoadingItems || !this.ntarLoadingValues || !this.quoteInsurer) {
        return
      }

      const linkedItem = this.innerValue.labours.find((i) => i.linkedItemId === repairItem.quoteItemId && i.isLoadItem && !i.deleted)
      let ntarLoadingItem = null
      if (this.innerValue.labourType === LabourTimeTypes.eMTA) {
        ntarLoadingItem = this.ntarOtherLoadingItems.find((n) => n.mtaItemDescription === repairItem.itemDesc && n.category === 'RA')
      } else {
        ntarLoadingItem = this.ntarLoadingItems.find((n) => n.itemNo === repairItem.itemNo && n.category === 'RA')
      }
      const hasPaintLoading =
        (ntarLoadingItem || isManualLoading) && (repairItem.itemType === ItemCategoryTypes.REP || repairItem.itemType === ItemCategoryTypes.PAINT)
      if (!hasPaintLoading && !linkedItem) {
        return
      } else if (linkedItem && (repairItem.hourValue === 0 || repairItem.reportOnly)) {
        // Delete paint loading
        const index = this.innerValue.labours.findIndex((i) => i.quoteItemId === linkedItem.quoteItemId)
        this.deleteItem(linkedItem, index)
        repairItem.loadingPercent = 0
        return
      }
      let paintItem =
        item.itemType === ItemCategoryTypes.PAINT
          ? this.innerValue.labours.find((i) => i.quoteItemId === item.quoteItemId && !i.deleted)
          : this.innerValue.labours.find(
              (i) => i.itemType === ItemCategoryTypes.PAINT && i.itemNo === repairItem.itemNo && i.itemDesc === repairItem.itemDesc && !i.deleted
            )
      let paintHour = 0
      if (!paintItem) {
        const paintItems = await this.getDefaultPaintItem(repairItem.itemNo)
        paintItem = paintItems[0]
        paintHour = paintItem.paintHour
      } else {
        paintHour = paintItem.hourValue
      }
      const paintLoadingItemNo = (parseInt(repairItem.itemNo, 10) + 5000).toString()
      let paintLoadingItem =
        linkedItem ||
        this.innerValue.labours.find((p) => p.itemType === ItemCategoryTypes.PAINT && p.itemNo === paintLoadingItemNo && p.isLoadItem && !p.deleted)
      let isNew = false
      const loadingValue = this.quoteInsurer.insurerLoadingValues.find(
        (v) => v.category === 'RA' && repairItem.hourValue >= v.lowerThreshold && repairItem.hourValue <= v.upperThreshold
      )
      const loadingPercent = (!loadingValue ? 0 : loadingValue.value) * 100
      if (!isManualLoading) {
        repairItem.loadingPercent = loadingPercent
      } else if (isManualLoading && loadingPercent > repairItem.loadingPercent) {
        repairItem.loadingPercent = loadingPercent
      }
      // Cannot find existing loading item then create new
      if (!paintLoadingItem) {
        paintLoadingItem = new QuoteItemModel(this.value.quoteId, paintLoadingItemNo, `Paint Loading, ${repairItem.itemDesc}`, ItemCategoryTypes.PAINT)
        paintLoadingItem.sortNo = this.value.length ? Math.max(...this.value.map((i) => i.sortNo)) + 1 : 1
        isNew = true
      } else if (loadingPercent > repairItem.loadingPercent) {
        // lll
      }
      paintLoadingItem.isLoadItem = true
      paintLoadingItem.linkedItemId = repairItem.quoteItemId
      paintLoadingItem.hourValue = roundAwayFromZero((paintHour * repairItem.loadingPercent) / 100)
      paintLoadingItem.rate = this.value.shopRate
      paintLoadingItem.dollarValue = roundAwayFromZero(paintLoadingItem.hourValue * this.value.shopRate)
      repairItem.linkedItemId = paintLoadingItem.quoteItemId
      if (isNew) {
        this.innerValue.labours.splice(this.innerValue.labours.length, 1, paintLoadingItem)
        paintLoadingItem.lineNumber = this.nextLineNumber
        this.innerValue.lines = paintLoadingItem.lineNumber + 1
      }
    },
    updateTotal(index, item) {},
    calculateItemCategoryTotal(quotingMethod, items, itemType) {
      let total = 0
      if (itemType === ItemCategoryTypes.PART) {
        total = items
          // eslint-disable-next-line no-unexpected-multiline
          .filter((i) => !i.deleted && !i.reportOnly)
          .reduce(function (total, item) {
            return roundAwayFromZero(total + item.itemQuantity * item.markupValue)
          }, 0)
      } else if (itemType === ItemCategoryTypes.MISC) {
        total = items
          // eslint-disable-next-line no-unexpected-multiline
          .filter((i) => !i.deleted && !i.reportOnly)
          .reduce(function (total, item) {
            return total + item.value
          }, 0)
      } else if (itemType === ItemCategoryTypes.OPG) {
        total = items
          // eslint-disable-next-line no-unexpected-multiline
          .filter((i) => !i.deleted && !i.reportOnly)
          .reduce(function (total, item) {
            return total + (quotingMethod === QuotingMethodTypes.Dollar ? item.dollarValue : item.hourValue * item.rate)
          }, 0)
      } else if (itemType === ItemCategoryTypes.SUBL) {
        total = items
          // eslint-disable-next-line no-unexpected-multiline
          .filter((i) => !i.deleted && !i.reportOnly)
          .reduce(function (total, item) {
            return total + item.value
          }, 0)
      } else {
        total = items
          // eslint-disable-next-line no-unexpected-multiline
          .filter((i) => !i.deleted && !i.reportOnly && i.itemType === itemType)
          .reduce(function (total, item) {
            return roundAwayFromZero(total + item.dollarValue)
          }, 0)
      }
      return total
    }
  }
}
</script>
