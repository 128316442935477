<template>
  <div class="item-row-quote-builder-parts quote-builder-item-row"
    :id="item.quoteItemId"
    :class="[{'has-background-grey-lighter is-selected' : isSelected, 'is-open' : isFullyExpanded}, defaultRowColourClass(item, getItemAssessments(item), false), rowDisplayStatus(item, getItemAssessments(item))]">
    <div @click="event => handleClick(event)">
      <!--------------------------------------------------------------- Main Grid --------------------------------------->
      <quote-builder-part-item-template :is-full-width="isFullWidth"
        :is-header="false"
        :is-fully-expanded="isFullyExpanded"
        @handleClick="handleClick">
        <!------- Description / partnumber ------->
        <template v-slot:description>

          <item-description-autocomplete v-if="isSelected && !item.deleteOtherItem && !innerValue.readOnly && !readOnlyView && !audanetLock && $company.setting.itemAutoCompleteEnabled && !isItemRemoved(item, getItemAssessments(item))"
            v-model="item.itemDesc"
            v-focus-next:callback="handleTransitionNextRow"
            :placeholder="'Item Description'"
            :id="item.quoteItemId + '-itemDesc'"
            :schedule-id="vehicle.scheduleId"
            :body-id="vehicle.bodyId"
            :class-prop="(!isValidItemDesc(item.quoteItemId) ? 'is-danger ': '') + defaultRowColourClass(item, getItemAssessments(item))"
            :item-type="ItemTypes.PARTS"
            :times-style="innerValue.labourType" />

          <input v-else-if="isSelected && !item.deleteOtherItem && !innerValue.readOnly && !readOnlyView && !audanetLock && !$company.setting.itemAutoCompleteEnabled && !isItemRemoved(item, getItemAssessments(item))"
            class="input"
            :class="[defaultRowColourClass(item, getItemAssessments(item)), !isValidItemDesc(item.quoteItemId) ? 'is-danger': '']"
            placeholder="Item Description"
            v-focus-inserted="{ focus: $userInfo.scrollItemPart }"
            :disabled="item.deleteOtherItem"
            v-model="item.itemDesc">

          <span v-else
            :id="item.quoteItemId + '-itemDesc'"
            class="is-quote-item-field-editable"
            :class="{'has-text-danger' : !isValidItemDesc(item.quoteItemId)}">
            {{ item.itemDesc || 'Item Description' }}
          </span>

          <input class="input is-uppercase"
            v-if="isSelected"
            :id="item.quoteItemId + '-itemNo'"
            :class="defaultRowColourClass(item)"
            placeholder="Part No."
            :disabled="item.deleteOtherItem"
            v-model="item.partNo">

          <span v-else
            :id="item.quoteItemId + '-itemNo'"
            class="is-italic is-quote-item-field-editable">{{ item.partNo || 'Part No.' }}</span>
        </template>

        <!-------------------Report ------------>
        <template v-slot:report>
          <div class="is-clickable mt-1"
            :class="{'disabled' : item.deleteOtherItem || !canMakeReportOnly(item)}"
            @click="$event => updateReport(item, $event)">
            <div class="is-flex ">
              <span v-tippy="{ theme: 'secondary', enabled: true }"
                content="Report"
                :class="item.reportOnly ? 'is-dark ' : 'is-light'"
                class="tag is-primary p-1 px-2">R
              </span>
            </div>
          </div>
        </template>

        <!------------------- Type ------------>
        <template v-slot:part_type>
          <div class="is-flex is-justify-content-center">
            <div v-if="isSelected && !item.deleteOtherItem && !innerValue.readOnly && !readOnlyView && !isItemRemoved(item, getItemAssessments(item))"
              class="select">
              <select v-model="item.mark"
                :class="defaultRowColourClass(item, getItemAssessments(item))"
                :disabled="item.deleteOtherItem"
                @change="onPartConditionChange(item)">
                <option v-for="(typeObject, type) in partTypes"
                  :value="typeObject.value"
                  :key="typeObject.value"
                  :title="typeObject.description">{{ type | formatPartCondition }}</option>
              </select>
            </div>
            <span class="mt-1"
              v-else>{{ item.mark | formatPartType }}</span>
          </div>
        </template>

        <!----------------- Quantity --------->
        <template v-slot:part_qty>
          <div :class="[defaultCellColourClass(item,'itemQuantity')]"
            :content="getTooltipCommunicationHistoryForField(item, 'itemQuantity')"
            v-tippy="getTooltipCommunicationHistoryOptionsForField(item, 'itemQuantity')">

            <vue-numeric v-if="isSelected && !innerValue.readOnly && !readOnlyView && !isItemRemoved(item, getItemAssessments(item))"
              class="input has-text-right"
              :class="defaultRowColourClass(item, getItemAssessments(item))"
              v-model.number="item.itemQuantity"
              v-focus-next:callback="handleTransitionNextRow"
              :id="item.quoteItemId + '-qty'"
              :minus="innerValue.subQuoteNo !== 0"
              :precision="0"
              @input="updateValue(index, item)" />
            <span v-else
              class="is-quote-item-field-editable end mt-1"
              :id="item.quoteItemId + '-qty'">{{ item.itemQuantity }}</span>
          </div>
        </template>

        <!-- buy price -->
        <template v-slot:part_buy>
          <div class="cell is-col-span-3">
            <vue-numeric v-if="isSelected && !item.deleteOtherItem && !innerValue.readOnly && !readOnlyView && !isItemRemoved(item, getItemAssessments(item))"
              class="input has-text-right"
              @input.native="handleOnPriceChange(item)"
              :class="defaultRowColourClass(item, getItemAssessments(item))"
              v-model.number="item.buyPrice"
              v-focus-next:callback="handleTransitionNextRow"
              :min="0"
              :id="item.quoteItemId + '-buy'"
              :disabled="item.deleteOtherItem || (item.quantityReceived>0 && !$userInfo.isSupportUser)"
              :precision="2" />
            <span v-else
              class="is-quote-item-field-editable end"
              :id="item.quoteItemId + '-buy'">{{ $filters.formatCurrency(item.buyPrice, $userInfo.locale, 2) }}</span>
          </div>
        </template>

        <!-- markup price -->
        <template v-slot:part_markup>
          <div class="cell is-col-span-3">
            <vue-numeric v-if="isSelected && !item.deleteOtherItem && !innerValue.readOnly && !readOnlyView && !isItemRemoved(item, getItemAssessments(item))"
              class="input has-text-right"
              :id="item.quoteItemId + '-markup'"
              :class="defaultRowColourClass(item, getItemAssessments(item))"
              v-model.number="item.markupValue"
              v-focus-next:callback="handleTransitionNextRow"
              @input.native="handleOnPriceChange(item)"
              :minus="true"
              :precision="2"
              :disabled="item.deleteOtherItem"
              @input="updateTotal()" />
            <span v-else
              class="is-quote-item-field-editable end mt-1"
              :id="item.quoteItemId + '-markup'">{{ $filters.formatCurrency(item.markupValue, $userInfo.locale, 2) }}</span>
          </div>
        </template>

        <!-- unit price -->
        <template v-slot:part_sell>
          <div class="cell is-col-span-3">
            <vue-numeric v-if="isSelected && !innerValue.readOnly && !readOnlyView && !isItemRemoved(item, getItemAssessments(item))"
              class="input has-text-right"
              :class="defaultRowColourClass(item, getItemAssessments(item))"
              v-model.number="item.value"
              v-focus-next:callback="handleTransitionNextRow"
              @input.native="handleOnPriceChange(item)"
              :minus="true"
              :id="item.quoteItemId + '-unit-price'"
              :precision="2"
              @input="updateMarkup(index, item)" />
            <span v-else
              class="is-quote-item-field-editable end"
              :id="item.quoteItemId + '-unit-price'">
              {{ $filters.formatCurrency(item.value, $userInfo.locale, 2) }}
            </span>
          </div>
        </template>

        <template v-slot:total_value>
          <div class="is-flex is-justify-content-end">
            <span class="is-quote-item-field-editable mt-1"
              :id="item.quoteItemId + '-total-val'">
              {{ $filters.formatCurrency( item.markupValue * item.itemQuantity, $userInfo.locale, false, 2) }}
            </span>
          </div>
        </template>

        <template v-slot:assessment_status>
          <supplementary-icon v-if="innerValue.quoteAssessments.length > 0"
            :item="item"
            :item-assessments="getItemAssessments(item)"
            :next-assessment-number="nextSupplemetryNo"
            :quoting-method="innerValue.quotingMethod"
            :icon-size="4"
            class="icon" />
        </template>

        <template v-slot:part_status>
          <button class="button is-ghost is-small tooltip px-0 mx-1"
            :data-tooltip="getPartStatus(item)?.title"
            @click="(e) => goToPartsCheck(e, item.partsCheckReferenceNo, 'quote', item)">
            <span :class="[getPartStatus(item)?.classStatus]" />
            <label />
          </button>
        </template>

        <template v-slot:delete_button>
          <button class="button is-ghost is-small tooltip px-0"
            data-tooltip="Delete Part"
            @click="deleteItem(item)">
            <span class="mdi mdi-delete mdi-24px has-text-danger is-clickable" />
          </button>
        </template>

        <template v-slot:drag_button>
          <button class="button is-ghost is-small tooltip item-drag-handle px-0"
            data-tooltip="Drag">
            <span class="mdi mdi-drag-vertical mdi-24px has-text-primary is-clickable" />
          </button>
        </template>

        <!-------------------------- Line Numer  --------------->
        <template v-slot:lineNo>
          <div v-tippy="{ theme: 'secondary', enabled: true, content: 'Line Number' }">
            <vue-numeric v-if="!innerValue.readOnly && $userInfo.isSupportUser && !readOnlyView && !isItemRemoved(item, getItemAssessments(item))"
              class="input has-text-right"
              :class="defaultRowColourClass(item, getItemAssessments(item))"
              v-model.number="item.lineNumber"
              v-focus-next:callback="handleTransitionNextRow"
              :precision="0"
              separator="" />
            <span v-else>{{ item.lineNumber }}</span>
          </div>
        </template>

        <!---------------------------- Item No  ----------------->
        <template v-slot:itemNo>
          <div v-tippy="{ theme: 'secondary', enabled: true, content: 'Item Number' }">
            <input v-if="!item.deleteOtherItem && !innerValue.readOnly && !readOnlyView && !audanetLock && !isItemRemoved(item, getItemAssessments(item))"
              class="input"
              :class="[defaultRowColourClass(item, getItemAssessments(item)), !isValidItemNo(item.quoteItemId) ? 'is-danger': '']"
              placeholder="Item No."
              :disabled="item.deleteOtherItem"
              v-model="item.itemNo"
              v-focus-next:callback="handleTransitionNextRow">
            <span v-else>{{ item.itemNo }}</span>
          </div>
        </template>

        <!----------------- Item Status ----------------->
        <template v-slot:itemStatus>
          <div v-tippy="{ theme: 'secondary', enabled: true, content: 'Item Status' }">
            <input v-if="!innerValue.readOnly && !readOnlyView && !isItemRemoved(item, getItemAssessments(item))"
              class="input is-capitalized"
              v-model="item.itemStatus"
              v-focus-next:callback="handleTransitionNextRow">
            <span v-else>{{ item.itemStatus }}</span>
          </div>
        </template>

      </quote-builder-part-item-template>
    </div>

  </div>

</template>

<script>
import vueNumeric from '@/components/VueNumeric/vue-numeric.vue'
import { QuoteItemStatusTypes, PartStatusTypes, PartTypes, ItemTypes, QuoteItemsOrderStatusTypes } from '@/enums'
import QuoteBuilderPartItemTemplate from '../../templates/QuoteBuilderPartItemTemplate.vue'
import ItemDescriptionAutocomplete from '../../../components/ItemDescriptionAutocomplete/ItemDescriptionAutocomplete.vue'
import { QuotePartMixin, QuoteItemValidationMixin, QuoteItemsMixin, QuoteAssessmentMixin, QuoteItemDifferenceMixin } from '@/views/quote/quoteBuilder/mixins'
import QuoteFiltersMixins from '../../../QuoteFiltersMixins'
import QuoteRoutes from '../../../route-types'
import supplementaryIcon from '@/views/quote/components/SupplementaryIcon.vue'
import { FocusNext } from '@/components/directives'
import { PartsCheckService } from '../../../services'

export default {
  name: 'QuoteBuilderPartItem',
  directives: { FocusNext },
  components: { supplementaryIcon, ItemDescriptionAutocomplete, vueNumeric, QuoteBuilderPartItemTemplate },
  filters: {
    formatPartType(value) {
      const index = Object.values(PartTypes).findIndex((item) => item.value === value)
      return Object.keys(PartTypes)[index]
    }
  },
  mixins: [QuoteFiltersMixins, QuoteItemValidationMixin, QuoteItemsMixin, QuoteAssessmentMixin, QuoteItemDifferenceMixin, QuotePartMixin],
  props: {
    index: {
      type: Number,
      default: 0
    },
    innerValue: {
      type: Object,
      required: true
    },
    // in a single row view, the row is full width
    isFullWidth: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      required: true
    },
    editItemId: null,
    fullyExpanded: false,
    nextLineNumber: {
      type: Number,
      default: 0
    },
    readOnlyView: {
      type: Boolean,
      default: false
    },
    audanetLock: {
      type: Boolean,
      default: false
    },
    vehicle: {
      type: Object,
      required: true
    },
    itemsAssessments: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      displayDeleted: false,

      itemType: ItemTypes.PARTS,
      // your data goes here
      isFloatingWidgetActive: false
    }
  },
  computed: {
    // your computed properties go here
    isSelected() {
      return this.item.quoteItemId === this.editItemId
    },
    isFullyExpanded() {
      return !!this.isSelected && !!this.fullyExpanded
    },
    PartStatusTypes() {
      return PartStatusTypes
    },
    partTypes() {
      return PartTypes
    },
    ItemTypes() {
      return ItemTypes
    },
    QuoteItemsOrderStatusTypes() {
      return QuoteItemsOrderStatusTypes
    },
    items() {
      if (this.innerValue) {
        return this.innerValue.parts
      }
    },
    nextSupplemetryNo() {
      const nextSuppNo = Math.max(...this.innerValue.quoteAssessments.map((i) => i.subQuoteNo)) + 1
      return nextSuppNo
    }
  },
  methods: {
    handleTransitionNextRow() {
      this.$emit('focusNextRow', this.item.quoteItemId)
    },
    handleOnPriceChange(item) {
      if (item.partStatus === PartStatusTypes.sentManualEnquiry) {
        item.partStatus = PartStatusTypes.pricedPartsControlManual
      }
    },
    closeModal() {
      this.isPresentAsModalActive = false
    },
    handleClick(event, expand = false, close = false) {
      event.stopPropagation()
      this.$emit('itemRowClicked', this.item, event, expand, close)
    },
    updateReport(item) {
      if (this.canMakeReportOnly(item)) {
        item.reportOnly = !item.reportOnly
        this.updateTotal()
      }
    },

    deleteItem(item) {
      let originalItemStatus = item.itemStatus
      this.deleteLinkedItem(item)
      if (item.isNew) {
        const itemIndex = this.innerValue.parts
          .map(function (obj) {
            return obj.quoteItemId
          })
          .indexOf(item.quoteItemId)
        if (itemIndex >= 0) {
          this.innerValue.parts.splice(itemIndex, 1)
          // this.items.splice(index, 1)
        }
      } else {
        item.deleted = true
        item.isDeleted = true
        item.itemStatus = QuoteItemStatusTypes.Deleted
        item.originalItemStatus = originalItemStatus
      }
      this.updateTotal()
      this.$snackbar.open({
        message: `Item <span class="is-italic">${item.itemDesc}</span> deleted`,
        type: 'is-danger',
        position: 'is-bottom',
        actionText: 'Undo',
        onAction: () => {
          if (item.isNew) {
            this.innerValue.parts.splice(this.innerValue.parts.length, 1, item)
          } else {
            item.deleted = false
            item.isDeleted = false
            item.itemStatus = originalItemStatus
          }
          this.updateTotal()
        }
      })
    },
    deleteLinkedItem(item) {
      if (this.isNtar && Guid.validGuid(item.linkedItemId)) {
        const linkedItem = this.innerValue.labours.find((i) => i.quoteItemId === item.linkedItemId && i.isLoadItem && !i.deleted)
        console.log(linkedItem)
        if (linkedItem) {
          this.deletePaintLoadingItem(linkedItem)
        }
      }
    },
    getItemAssessments(item) {
      const assessments = this.itemsAssessments
      const itemAssessments = assessments.find((i) => i.quoteItemId == item.quoteItemId)
      if (!!itemAssessments) return itemAssessments.assessments
      return []
    },
    updateTotal() {
      this.isFloatingWidgetActive = true
    },
    getPartStatus(part) {
      let statusObj = {
        class: '',
        title: ''
      }
      if (!part.partStatus && part.quantityOrdered == 0 && part.quantityReceived == 0) {
        statusObj = QuoteItemsOrderStatusTypes.NotRequested
      } else if (part.partStatus === PartStatusTypes.sentManualEnquiry && part.quantityOrdered == 0 && part.quantityReceived == 0) {
        statusObj = QuoteItemsOrderStatusTypes.Requested
      } else if (part.partStatus === PartStatusTypes.pricedPartsControlManual && part.quantityOrdered == 0 && part.quantityReceived == 0) {
        statusObj = QuoteItemsOrderStatusTypes.Priced
      } else if (part.partStatus === 'Exp' && part.quantityOrdered == 0 && part.quantityReceived == 0) {
        statusObj = QuoteItemsOrderStatusTypes.Requested
      } else if (part.partStatus === 'Imp' && part.quantityOrdered == 0 && part.quantityReceived == 0) {
        statusObj = QuoteItemsOrderStatusTypes.Priced
      } else if (part.partStatus === 'Pord' && part.quantityOrdered == 0 && part.quantityReceived == 0) {
        statusObj = QuoteItemsOrderStatusTypes.PendingOrder
      } else if (
        (part.quantityOrdered > 0 && part.quantityReceived == 0 && part.quantityReturned == 0) ||
        (part.quantityOrdered > part.quantityReturned && part.quantityReceived == 0)
      ) {
        statusObj = QuoteItemsOrderStatusTypes.ItemOrdered
      } else if (part.quantityOrdered > 0 && part.quantityReturned > 0 && part.quantityOrdered > part.quantityReturned) {
        statusObj = QuoteItemsOrderStatusTypes.PartiallyReturned
      } else if (part.quantityOrdered > 0 && part.quantityReturned > 0 && part.quantityOrdered == part.quantityReturned) {
        statusObj = QuoteItemsOrderStatusTypes.AllItemsReturned
      } else if (part.quantityOrdered > 0 && part.quantityReceived > 0 && part.quantityOrdered == part.quantityReceived) {
        statusObj = QuoteItemsOrderStatusTypes.FullyReceived
      } else if (part.quantityOrdered > 0 && part.quantityReceived > 0 && part.quantityOrdered !== part.quantityReceived) {
        statusObj = QuoteItemsOrderStatusTypes.PartiallyReceived
      }
      return {
        classStatus: statusObj.class,
        title: statusObj.title
      }
    },
    async goToPartsCheck(e, partsCheckReferenceNo, searchType, part) {
      const isManualPartOrder = !part.partStatus && (part.quantityOrdered !== 0 || part.quantityReceived !== 0)
      if (isManualPartOrder) {
        this.$nextTick(() => {
          this.$router.push({
            name: QuoteRoutes.QuotePartsControl.name
          })
        })
        return
      }
      // Not priced Yet
      if (!part.partStatus) {
        this.$toast.open({
          message: 'Please request the part before proceeding to PartsCheck.',
          type: 'is-warning',
          position: 'is-bottom',
          queue: false
        })
        return
      } else if (
        ///// requested and/or priced // pending order
        (part.partStatus === 'Exp' && part.quantityOrdered == 0 && part.quantityReceived == 0) ||
        (part.partStatus === 'Imp' && part.quantityOrdered == 0 && part.quantityReceived == 0) ||
        (part.partStatus === 'Pord' && part.quantityOrdered == 0 && part.quantityReceived == 0)
      ) {
        '/' + this.innerValue.subQuoteNo
        const quoteNo = this.innerValue.prefix + this.innerValue.quoteNo + this.innerValue.suffix
        const result = await PartsCheckService.goToPartsCheck(quoteNo, this.innerValue.subQuoteNo, partsCheckReferenceNo, searchType)
        if (result) window.open(result, '_blank')
      } else if (
        ///// ordered, received, returned, etc.
        (part.quantityOrdered > 0 && part.quantityReceived == 0 && part.quantityReturned == 0) ||
        (part.quantityOrdered > 0 && part.quantityReceived > 0 && part.quantityOrdered == part.quantityReceived) ||
        (part.quantityOrdered > 0 && part.quantityReceived > 0 && part.quantityOrdered !== part.quantityReceived) ||
        (part.quantityOrdered > 0 && part.quantityReturned > 0 && part.quantityOrdered == part.quantityReturned) ||
        (part.quantityOrdered > 0 && part.quantityReturned > 0 && part.quantityOrdered !== part.quantityReturned)
      ) {
        const quoteNo = this.innerValue.prefix + this.innerValue.quoteNo + this.innerValue.suffix
        const result = await PartsCheckService.goToPartsCheck(quoteNo, this.innerValue.subQuoteNo, partsCheckReferenceNo, 'po')
        if (result) window.open(result, '_blank')
      }
    },
    canMakeReportOnly(item) {
      const assessments = this.getItemAssessments(item)
      if (assessments.length > 0) {
        const lastAssessment = assessments[assessments.length - 1]
        const lastValues = lastAssessment[lastAssessment.length - 1]
        if (!lastValues.reportOnly) {
          return false
        }
      }
      return true
    }
  }
}
</script>

<style lang="scss">
.item-row-quote-builder-parts {
  height: 55px !important;
  border: 1px solid #adb5bd;
  border-radius: 5px;
  font-size: 0.9em;
  margin-bottom: 5px;
  padding: 2px;

  .center-y {
    display: flex;
    align-items: center;
  }

  .center {
    display: flex;
    justify-content: center;
  }
  .end {
    display: flex;
    justify-content: flex-end;
  }

  &.is-selected {
    height: 85px !important;
  }

  &.is-open {
    height: 120px !important;
  }
}

/* text overflow ellipses */
.item-row-quote-builder-parts span {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>