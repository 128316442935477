<template>
  <div class="item-row-quote-builder-labour quote-builder-item-row"
    :id="item.quoteItemId"
    :class="[{'has-background-grey-lighter is-selected' : isSelected, 'is-open' : isFullyExpanded && !isFullWidth}, defaultRowColourClass(item, getItemAssessments(item), displayDeleted), rowDisplayStatus(item, getItemAssessments(item))]">

    <!--------------------------------------------------------------- Main Template --------------------------------------->
    <quote-builder-labour-item-template :is-full-width="isFullWidth"
      :is-quote-hour="isQuoteTypeHour"
      :is-fully-expanded="isFullyExpanded"
      :is-selected="isSelected"
      :is-header="false"
      :is-paint-item="isPaint"
      :is-ntar="isNtar"
      :show-paint-loading="showLoading"
      @handleClick="handleClick">

      <!------- Description ------->
      <template v-slot:description>
        <div :content="getTooltipCommunicationHistoryForField(item, 'itemDesc')"
          v-tippy="getTooltipCommunicationHistoryOptionsForField(item, 'itemDesc')"
          :class="[defaultCellColourClass(item,'itemDesc')]"
          :style="{ 'width': '100%' }"
          class="is-flex">
          <item-description-autocomplete v-if="isSelected && !item.deleteOtherItem && !innerValue.readOnly && !readOnlyView && !audanetLock && $company.setting.itemAutoCompleteEnabled"
            v-model="item.itemDesc"
            :style="{ 'width': '100%' }"
            :schedule-id="vehicle.scheduleId"
            :body-id="vehicle.bodyId"
            v-focus-next
            :id="item.quoteItemId + '-itemDesc'"
            :class-prop="(!isValidItemDesc(item.quoteItemId) ? 'is-danger ': '') + defaultRowColourClass(item)"
            :item-type="ItemTypes.LABOUR"
            :times-style="innerValue.labourType"
            @select="onSelectItem(item, $event)" />
          <input v-else-if="isSelected && !item.deleteOtherItem && !innerValue.readOnly && !readOnlyView && !audanetLock && !$company.setting.itemAutoCompleteEnabled && !isItemRemoved(item, getItemAssessments(item))"
            class="input editable-field"
            :id="item.quoteItemId + '-itemDesc'"
            :class="[defaultRowColourClass(item), !isValidItemDesc(item.quoteItemId) ? 'is-danger': '']"
            placeholder="Item Description"
            v-focus-inserted="{ focus: $userInfo.scrollItemLabour }"
            v-model="item.itemDesc"
            @input="setItemStatus(item)">
          <span :id="item.quoteItemId + '-itemDesc'"
            class="is-quote-item-field-editable"
            v-else
            :class="{'is-italic has-text-danger': !item.itemDesc}">
            {{ item.itemDesc || 'Item Description' }} {{ item.blend ? '+ BLENDING' : '' }}
          </span>
          <div v-if="item.itemComment"
            class="help is-primary">
            <span class="has-text-weight-bold">Comment: </span>
            <span>{{ item.itemComment }}</span>
          </div>
        </div>
      </template>

      <!------------------ Loading ------------>

      <template v-slot:loading>
        <div v-if="!innerValue.readOnly && !readOnlyView && !audanetLock && !isItemRemoved(item, getItemAssessments(item))"
          v-show="!item.isLoadItem && showLoading">
          <div class="select"
            v-if="isSelected">
            <select v-model="item.loadingPercent"
              :id="item.quoteItemId + '-hourValue'"
              :class="defaultRowColourClass(item, getItemAssessments(item), false)"
              @change="changeLoadingPercent(item)">
              <option :value="0">0%</option>
              <option :value="10">10%</option>
              <option :value="25">25%</option>
            </select>
          </div>
          <span class="is-quote-item-field-editable"
            :id="item.quoteItemId + '-hourValue'"
            v-else>{{ item.loadingPercent }}%</span>
        </div>
      </template>

      <!------------------- Report ------------>
      <template v-slot:report>
        <div class="is-clickable"
          :class="{'disabled' : item.deleteOtherItem || !canMakeReportOnly(item)}"
          @click="$event => updateReport(item, $event)">
          <div class="is-flex ">
            <span v-tippy="{ theme: 'secondary', enabled: true }"
              content="Report"
              :class="item.reportOnly ? 'is-dark ' : 'is-light'"
              class="tag is-primary p-1 px-2">R
            </span>
          </div>
        </div>
      </template>

      <!------------------- Blend ------------>
      <template v-slot:blend>
        <div class="is-clickable"
          @click="$event => updateBlend(item, $event)">
          <div class="is-flex ">
            <span v-tippy="{ theme: 'secondary', enabled: true }"
              content="Blend"
              :class="item.blend ? 'is-dark ' : 'is-light'"
              class="tag is-link p-1 px-2">B
            </span>
          </div>
        </div>
      </template>

      <!------------------- Rates ------------>
      <template v-slot:rates>
        <div :class="[{ 'tooltip' : isItemInvoiced(item) === true}]"
          data-tooltip="Item rate is already invoiced!">
          <vue-numeric v-if="isSelected && !innerValue.readOnly && !readOnlyView && !audanetLock && !isItemRemoved(item, getItemAssessments(item)) && !isItemInvoiced(item)"
            :id="item.quoteItemId + '-rate'"
            class="input has-text-right editable-field skip-tab"
            placeholder="Rate"
            v-focus-next
            :class="[ defaultRowColourClass(item, getItemAssessments(item), false), item.rate !== getLabourRateEx(item).rate ? 'is-warning' : '' ]"
            :min="0"
            @blur="onRateChange(item)"
            v-model.number="item.rate" />
          <div v-else
            class="is-flex is-justify-content-end is-quote-item-field-editable"
            :id="item.quoteItemId + '-rate'">
            {{ $filters.formatCurrency(item.rate, $userInfo.locale, false, 2) }}
          </div>
        </div>
      </template>

      <!------------------- Hour   ------------>
      <template v-slot:hour_value
        :class="[defaultCellColourClass(item,'hourValue')]"
        :content="getTooltipCommunicationHistoryForField(item, 'hourValue')"
        v-tippy="getTooltipCommunicationHistoryOptionsForField(item, 'hourValue')">
        <div class="is-flex is-justify-content-end">
          <vue-numeric v-if="isSelected && !innerValue.readOnly && !readOnlyView && !audanetLock && !isItemRemoved(item, getItemAssessments(item))"
            :id="item.quoteItemId + '-hourValue'"
            class="input has-text-right editable-field"
            placeholder="h(s)"
            :class="defaultRowColourClass(item, getItemAssessments(item), false)"
            :min="0"
            v-focus-next:callback="handleTransitionNextRow"
            @blur="updateValue(item)"
            :precision="2"
            v-model="item.hourValue" />
          <span v-else-if="isQuoteHour"
            class="is-quote-item-field-editable"
            :id="item.quoteItemId + '-hourValue'">
            {{ item.hourValue?.toFixed(2) }}
          </span>
          <span v-else
            class="is-quote-item-field-editable"
            :id="item.quoteItemId + '-hourValue'">
            {{ $filters.formatCurrency(item.hourValue, $userInfo.locale, false, 2) }}
          </span>
        </div>
      </template>

      <!------------------- Total Value ------------>
      <template v-slot:total_value>
        <div class="is-flex is-justify-content-end is-align-items-center">
          <span>
            {{ $filters.formatCurrency( item.dollarValue, $userInfo.locale, false, 2) }}
          </span>
        </div>
      </template>

      <!------------------- Present As ------------>
      <template v-slot:present_as>
        <present-as-component :item="item"
          @handlePresentAs="$emit('handlePresentAs', item.quoteItemId)" />
      </template>

      <!--------------------- Assessment Status  ----------->
      <template v-slot:assessment_status>
        <supplementary-icon v-if="innerValue.quoteAssessments.length > 0"
          :item="item"
          :item-assessments="getItemAssessments(item)"
          :next-assessment-number="nextSupplemetryNo"
          :quoting-method="innerValue.quotingMethod"
          :icon-size="4"
          class="icon" />
      </template>

      <!------------------- Delete button ------------>
      <template v-slot:delete
        v-if="!audanetLock && !isItemRemoved(item, getItemAssessments(item)) && (item.isDeleted === false || item.deleted === false)">
        <button class=" button is-ghost is-small tooltip p-0 mx-1"
          data-tooltip="Delete Labour Item"
          @click="deleteItem(item)">
          <span class="mdi mdi-delete mdi-24px has-text-danger is-clickable" />
        </button>
      </template>

      <!------------------- Drag button ------------>
      <template v-slot:draggable>
        <button class="button is-ghost is-small tooltip item-drag-handle px-0"
          data-tooltip="Drag"
          :disabled="innerValue.readOnly || readOnlyView">
          <span class="mdi mdi-drag-vertical mdi-24px has-text-primary is-clickable" />
        </button>
      </template>

      <!----------------- Line No  ------------------------>
      <template v-slot:lineNo>
        <div v-tippy="{ theme: 'secondary', enabled: true, content: 'Line Number' }">
          <vue-numeric v-if="(!innerValue.readOnly && $userInfo.isSupportUser && !readOnlyView) && !isItemRemoved(item, getItemAssessments(item))"
            class="input has-text-right"
            :class="defaultRowColourClass(item, getItemAssessments(item), false)"
            v-model.number="item.lineNumber"
            v-focus-next
            :precision="0"
            separator="" />
          <span v-else>{{ item.lineNumber }}</span>
        </div>
      </template>

      <!----------------- Item No  ------------------------>
      <template v-slot:itemNo>
        <div v-tippy="{ theme: 'secondary', enabled: true, content: 'Item Number' }">
          <input v-if="!item.deleteOtherItem && !innerValue.readOnly && !readOnlyView && !audanetLock && !isItemRemoved(item, getItemAssessments(item))"
            class="input"
            :class="[defaultRowColourClass(item, getItemAssessments(item), false), !isValidItemNo(item.quoteItemId) ? 'is-danger': '']"
            placeholder="Item No."
            v-focus-next
            v-model="item.itemNo"
            @input="setItemStatus(item)">
          <span v-else>{{ item.itemNo }}</span>
        </div>
      </template>

      <!------------------Item Status  -------------------->
      <template v-slot:itemStatus>
        <div v-tippy="{ theme: 'secondary', enabled: true, content: 'Item Status' }">
          <input v-if="!innerValue.readOnly && !readOnlyView && !isItemRemoved(item, getItemAssessments(item))"
            class="input is-capitalized"
            v-focus-next:callback="handleTransitionNextRow"
            v-model="item.itemStatus">
          <span v-else>{{ item.itemStatus }}</span>
        </div>
      </template>

      <!------------------- Paint Group ------------>
      <template v-slot:paint_group
        v-if="isPaint">
        <div v-if="isSelected && vehicle && !item.deleteOtherItem && !innerValue.readOnly && !readOnlyView && !audanetLock && !isItemRemoved(item, getItemAssessments(item))"
          class="select">
          <select v-model="item.opgCode"
            :class="defaultRowColourClass(item, getItemAssessments(item), false)"
            v-focus-next:callback="handleTransitionNextRow"
            @change="getOpgRate(item, index)">
            <option v-for="(paintGroup) in paintGroupTypes"
              :value="paintGroup"
              :key="paintGroup"
              :class="{ 'has-text-success' : paintGroup === vehicle.paintGroup }">{{ paintGroup }}</option>
          </select>
        </div>
        <span v-else>{{ item.opgCode || '--' }}</span>
      </template>

    </quote-builder-labour-item-template>

  </div>

</template>

<script>
import vueNumeric from '@/components/VueNumeric/vue-numeric.vue'
import { ItemCategoryTypes, QuotingMethodTypes, QuoteItemStatusTypes, ItemTypes, PaintGroupTypes } from '@/enums'
import _cloneDeep from 'lodash/cloneDeep'
import QuoteBuilderLabourItemTemplate from '../../templates/QuoteBuilderLabourItemTemplate.vue'
import { QuoteItemValidationMixin, QuoteItemsMixin, QuoteAssessmentMixin, QuoteItemDifferenceMixin, QuoteLabourMixin } from '@/views/quote/quoteBuilder/mixins'
import ItemDescriptionAutocomplete from '../../../components/ItemDescriptionAutocomplete/ItemDescriptionAutocomplete.vue'
import PresentAsComponent from '../../components/PresentAsComponent.vue'
import supplementaryIcon from '@/views/quote/components/SupplementaryIcon.vue'
import { roundAwayFromZero } from '@/components/utils/AccountingFunctions'
import { FocusNext } from '@/components/directives'
import Guid from '@/components/Guid'

export default {
  name: 'QuoteBuilderLabourItem',
  components: { vueNumeric, QuoteBuilderLabourItemTemplate, ItemDescriptionAutocomplete, PresentAsComponent, supplementaryIcon },

  directives: {
    FocusNext
  },
  mixins: [QuoteItemValidationMixin, QuoteItemsMixin, QuoteAssessmentMixin, QuoteItemDifferenceMixin, QuoteLabourMixin],
  props: {
    innerValue: {
      type: Object,
      required: true
    },
    // in a single row view, the row is full width
    isFullWidth: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      required: true
    },
    editItemId: null,
    fullyExpanded: false,
    nextLineNumber: {
      type: Number,
      default: 0
    },
    readOnlyView: {
      type: Boolean,
      default: false
    },
    audanetLock: {
      type: Boolean,
      default: false
    },
    isQuoteTypeHour: {
      type: Boolean,
      default: false
    },
    vehicle: {
      type: Object,
      required: true
    },
    itemsAssessments: {
      type: Array,
      default: () => []
    },
    isPaint: {
      type: Boolean,
      default: false
    },
    showLoading: {
      type: Boolean,
      default: false
    },
    isNtar: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      displayDeleted: false,
      itemType: ItemTypes.LABOUR,
      isPresentAsModalActive: false,
      filter: {
        sortColumn: '',
        sortOrder: '',
        pageIndex: 1,
        pageSize: 100
      }
    }
  },
  computed: {
    // your computed properties go here
    isSelected() {
      return this.item.quoteItemId === this.editItemId
    },
    isFullyExpanded() {
      return !!this.isSelected && !!this.fullyExpanded
    },
    ItemTypes() {
      return ItemTypes
    },
    isQuoteHour() {
      return this.isQuoteTypeHour
    },
    paintGroupTypes() {
      return PaintGroupTypes
    },
    nextSupplemetryNo() {
      const nextSuppNo = Math.max(...this.innerValue.quoteAssessments.map((i) => i.subQuoteNo)) + 1
      return nextSuppNo
    }
  },
  methods: {
    changeLoadingPercent(item) {
      // console.log('changeLoadingPercent')
      this.addUpdateRepairPaintLoading(item, true)
    },

    handleTransitionNextRow() {
      this.$emit('focusNextRow', this.item.quoteItemId)
    },
    closeModal() {
      this.isPresentAsModalActive = false
    },
    handleClick(event, expand = false, close = false) {
      event.stopPropagation()
      this.$emit('itemRowClicked', this.item, event, expand, close)
    },
    updateBlend(item, event) {
      if (
        !item.deleteOtherItem &&
        !this.innerValue.readOnly &&
        !this.readOnlyView &&
        !this.audanetLock &&
        !this.isItemRemoved(item, this.getItemAssessments(item))
      ) {
        // event.stopPropagation()
        item.blend = !item.blend
        const isBlend = item.blend
        const itemToUpdate = this.innerValue.labours.find((i) => i.quoteItemId === item.quoteItemId)
        if (item.itemType === ItemCategoryTypes.PAINT && this.isNtar) {
          const value = isBlend ? roundAwayFromZero(item.hourValue * 0.7) : roundAwayFromZero(item.hourValue / 0.7)
          if (this.item.quotingMethod === QuotingMethodTypes.Dollar) {
            itemToUpdate.hourValue = value
            itemToUpdate.dollarValue = item.hourValue
          } else {
            itemToUpdate.hourValue = value
            itemToUpdate.dollarValue = roundAwayFromZero(item.hourValue * item.rate)
          }
          this.updateTotal()
        }
      }
    },
    onRateChange(item) {
      this.$emit('updateValue', item)
    },
    updateReport(item, event) {
      // event.stopPropagation()
      if (!item.deleteOtherItem && !this.innerValue.readOnly && !this.readOnlyView && !this.audanetLock && this.canMakeReportOnly(item)) {
        item.reportOnly = !item.reportOnly
      }
    },
    deletePaintLoading(item) {
      if (this.isNtar && Guid.validGuid(item.linkedItemId)) {
        const linkedItem = this.innerValue.labours.find((i) => i.quoteItemId === item.linkedItemId && i.isLoadItem && !i.deleted)
        const index = this.innerValue.labours.findIndex((i) => i.quoteItemId === item.linkedItemId && i.isLoadItem && !i.deleted)
        console.log(linkedItem)
        if (linkedItem) {
          this.deleteItem(linkedItem, index)
        }
      }
    },
    deleteItem(item, index) {
      let originalItemStatus = item.itemStatus
      this.deletePaintLoading(item)
      const deletedItem = _cloneDeep(item)
      console.log(deletedItem)
      if (item.isNew) {
        const itemIndex = this.innerValue.labours
          .map(function (obj) {
            return obj.quoteItemId
          })
          .indexOf(item.quoteItemId)
        if (itemIndex >= 0) {
          this.innerValue.labours.splice(itemIndex, 1)
          // this.items.splice(index, 1)
        }
      } else {
        item.deleted = true
        item.isDeleted = true
        item.itemStatus = QuoteItemStatusTypes.Deleted
        item.originalItemStatus = originalItemStatus
      }
      this.addUpdateRepairPaintLoading(deletedItem)

      this.$snackbar.open({
        message: `Item <span class="is-italic">${item.itemDesc}</span> deleted`,
        type: 'is-danger',
        position: 'is-bottom',
        actionText: 'Undo',
        onAction: () => {
          if (item.isNew) {
            this.innerValue.labours.splice(this.innerValue.labours.length, 1, item)
            // this.items.splice(this.items.length, 1, item)
          } else {
            item.deleted = false
            item.isDeleted = false
            item.itemStatus = originalItemStatus
          }
          this.updateTotal()
        }
      })
    },
    getItemAssessments(item) {
      const assessments = this.itemsAssessments
      const itemAssessments = assessments.find((i) => i.quoteItemId == item.quoteItemId)
      if (!!itemAssessments) return itemAssessments.assessments
      return []
    },
    onSelectItem(currentItem, selectedItem) {
      let itemNo = ''
      if (selectedItem.itemNo !== '') {
        itemNo = selectedItem.itemNo
      } else if (selectedItem.bmItemNo !== '') {
        itemNo = selectedItem.bmItemNo
      } else if (selectedItem.alphaCode !== '') {
        itemNo = selectedItem.alphaCode
      } else if (selectedItem.bmAlphaCode !== '') {
        itemNo = selectedItem.bmAlphaCode
      } else if (selectedItem.pnpAlphaCode !== '') {
        itemNo = selectedItem.pnpAlphaCode
      }
      currentItem.itemNo = itemNo
      currentItem.itemDesc = selectedItem.description
    },
    canMakeReportOnly(item) {
      const assessments = this.getItemAssessments(item)
      if (assessments.length > 0) {
        const lastAssessment = assessments[assessments.length - 1]
        const lastValues = lastAssessment[lastAssessment.length - 1]
        if (!lastValues.reportOnly) {
          return false
        }
      }
      return true
    }
  }
}
</script>

<style lang="scss">
.item-row-quote-builder-labour {
  display: grid;
  height: 45px !important;
  border: 1px solid #adb5bd;
  border-radius: 5px;
  font-size: 0.9em;
  margin-bottom: 5px;
  padding: 2px;
  // animate height
  transition: height 0.05s;

  .center-y {
    display: flex;
    align-items: center;
  }

  .center {
    display: flex;
    justify-content: center;
  }
  .end {
    display: flex;
    justify-content: flex-end;
  }

  &.is-open {
    height: 85px !important;
  }
}

/* text overflow ellipses */
.item-row-quote-builder-labour span {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>