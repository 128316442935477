
<template>
  <div class="m-3">
    <article class="tile is-child box p-2">
      <div class="is-flex is-align-items-center mb-3 is-justify-content-space-between">

        <div class="is-flex is-align-items-center">

          <span class="px-3 is-size-4 has-text-weight-bold">Quote Items</span>
          <!-- vertical break -->
          <!-- <div class="is-divider-vertical" /> -->

          <div class="is-flex  ml-2">
            <span class="mr-2">Items:</span>
            <span class="has-text-success has-text-weight-bold">
              {{ $filters.formatCurrency( totalQuoteItems, $userInfo.locale, false, 2) }}
            </span>
          </div>

          <div class="is-flex ml-3"
            v-if="totalQuoteItems != innerValue.totalExGst">
            <span class="mr-2">Grand Total:</span>
            <div class="has-text-success has-text-weight-bold is-underlined is-clickable"
              @click="handleSummaryClick">
              <span class="">
                {{ $filters.formatCurrency( innerValue.totalExGst, $userInfo.locale, false, 2) }}
              </span>
            </div>
          </div>

        </div>
        <div class="is-flex">
          <div class="is-flex is-align-items-center is-size-5 mr-4"
            :class="{'disabled' : isAvailableItemsOpen}">

            <div class="buttons has-addons">
              <button class="button"
                @click="selectedViewType = viewTypes.singleColView"
                :class="selectedViewComputed == viewTypes.singleColView ? 'is-selected has-text-primary': 'has-text-grey'">
                <span class="mdi mdi-view-split-horizontal mdi-24px mr-1" />
                <span>Full</span>
              </button>
              <button class="button"
                @click="selectedViewType = viewTypes.multiColView"
                :class="selectedViewComputed == viewTypes.multiColView ? 'is-selected has-text-primary': 'has-text-grey'">
                <span class="mdi mdi-view-split-vertical mdi-24px mr-1" />
                <span>Dual</span>
              </button>
              <button class="button"
                @click="selectedViewType = viewTypes.tabbedView"
                :class="selectedViewComputed == viewTypes.tabbedView ? 'is-selected has-text-primary': 'has-text-grey'">
                <span class="mdi mdi-tab mdi-24px mr-1" />
                <span>Tabs</span>
              </button>
            </div>
          </div>

          <button v-if="value.subQuoteNo > 0"
            class="button is-success tooltip is-tooltip-topright"
            data-tooltip="Adjust Main Quote Items"
            @click="handleToggleAvaliableMainItems">
            <span class="icon">
              <i class="mdi"
                :class="{ 'mdi-adjust' : !isAvailableMainActive, 'mdi-close' : isAvailableMainActive }" />
            </span>
            <span>{{ isAvailableMainActive ? 'Done' : 'Adjust' }}</span>
          </button>

          <div class="button is-primary mx-4"
            @click="handleToggleAvaliableItems">
            {{ isAvailableItemsOpen ? '- Close' : '+ Add' }}
          </div>
        </div>
      </div>

      <!------------------------- Available Item  -------------------------->
      <div v-if="!!isAvailableItemsOpen || !!isAvailableMainActive"
        class="mb-4">
        <div class="tabs">
          <ul>
            <li :class="{'is-active': avaliableItemsType == 'LABOUR'}"
              @click="avaliableItemsType = 'LABOUR'">
              <a>LABOUR</a>
            </li>
            <li :class="{'is-active': avaliableItemsType == 'OTHER'}"
              @click="avaliableItemsType = 'OTHER'">
              <a>MECH</a>
            </li>
            <li :class="{'is-active': avaliableItemsType == 'PARTS'}"
              @click="avaliableItemsType = 'PARTS'">
              <a>PARTS</a>
            </li>
            <li :class="{'is-active': avaliableItemsType == 'MISC'}"
              @click="avaliableItemsType = 'MISC'">
              <a>MISC.</a>
            </li>
            <li :class="{'is-active': avaliableItemsType == 'SUBLETS'}"
              @click="avaliableItemsType = 'SUBLETS'">
              <a>SUBLETS</a>
            </li>
          </ul>
        </div>

        <add-items-component :value="value"
          :vehicle="vehicle"
          :inner-value="innerValue"
          :next-line-number="nextLineNumber"
          :is-available-items-active="isAvailableItemsOpen"
          :avaliable-items-type="avaliableItemsType"
          :is-main-active="isAvailableMainActive"
          @handleUpdate="handleUpdate" />
      </div>

      <div class="tabs is-boxed"
        v-if="selectedViewType == viewTypes.tabbedView && !isAvailableItemsOpen">
        <ul>
          <li :class="{'is-active': selectedTab.id == tab.id}"
            v-for="(tab) in dataTypes"
            :key="tab.title"
            @click="selectedTab = tab">
            <a>
              <span>{{ tab.title }}</span>
            </a>
          </li>
        </ul>
      </div>
      <!-- large/multi row/tab view -->
      <quote-builder-items-view :selected-view-type="selectedViewComputed"
        :inner-value="innerValue"
        :selected-tab="selectedTab"
        :view-types="viewTypes"
        :value="value"
        :is-available-items-open="isAvailableItemsOpen"
        :avaliable-items-type="avaliableItemsType"
        :is-present-as-modal-active="isPresentAsModalActive"
        :next-line-number="nextLineNumber"
        @handleUpdate="handleUpdate"
        @handlePresentAs="handlePresentAs"
        :vehicle="vehicle" />
    </article>

    <quote-item-present-as-modal v-if="isPresentAsModalActive"
      :active.sync="isPresentAsModalActive"
      v-model="item.presentAs"
      :maximum-options="2"
      @ok="closePresentAsModal()"
      @cancel="closePresentAsModal()"
      @close="closePresentAsModal()">
      <p slot="text-title">Present As</p>
    </quote-item-present-as-modal>

  </div>
</template>

<script>
import { ItemCategoryTypes } from '@/enums'
import QuoteBuilderItemsView from './QuoteBuilderItemsView.vue'
import { QuoteItemPresentAsModal } from '@/components/BulmaModal'
import StoreUtil from '@/store/utils'
import AddItemsComponent from './components/AddItemsComponent.vue'
import cloneDeep from 'lodash.clonedeep'
import _debounce from 'lodash.debounce'
import { QuoteTotalsMethodMixin } from './mixins'
import QuoteRoutes from '../route-types'

export default {
  name: 'QuoteBuilderView',
  components: { QuoteBuilderItemsView, QuoteItemPresentAsModal, AddItemsComponent, QuoteItemPresentAsModal, QuoteItemPresentAsModal },
  mixins: [QuoteTotalsMethodMixin],
  props: {
    value: {
      type: Object,
      required: true
    },
    nextLineNumber: {
      type: Number,
      default: 0
    },
    vehicle: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      innerValue: null,
      isAvailableItemsOpen: false,
      isAvailableMainActive: false,
      avaliableItemsType: 'LABOUR',
      dataTypes: [
        { id: 0, key: 'RR', title: 'Remove & Refit', source: 'labours', type: ItemCategoryTypes.RR, source: 'labours' },
        { id: 2, key: 'Repair', title: 'Repair', source: 'labours', type: ItemCategoryTypes.REP, source: 'labours' },
        { id: 3, key: 'Paint', title: 'Paint', source: 'labours', type: ItemCategoryTypes.PAINT, source: 'labours' },
        { id: 4, key: 'MECH', title: 'Mechanical', source: 'others', type: ItemCategoryTypes.MECH, source: 'labours' },
        { id: 5, key: 'Parts', title: 'Parts', source: 'parts', type: ItemCategoryTypes.PART, source: 'parts' },
        { id: 6, key: 'Misc', title: 'Miscellaneous', source: 'miscs', type: ItemCategoryTypes.MISC, source: 'misc' },
        { id: 7, key: 'Sublets', title: 'Sublets', source: 'sublets', type: ItemCategoryTypes.SUBL, source: 'sublets' }
      ],
      totals: {
        parts: 0,
        labours: 0,
        others: 0,
        miscs: 0,
        sublets: 0
      },
      // view types for the quote items
      viewTypes: {
        singleColView: 'singleCol',
        multiColView: 'multiCol',
        tabbedView: 'tabView'
      },
      selectedViewType: 'singleCol',
      //just used for tabbed view
      selectedTab: { id: 0, title: 'Remove && Refit', source: 'labours', type: ItemCategoryTypes.RR },
      isPresentAsModalActive: false,
      presentAsItem: null
    }
  },
  computed: {
    routeTypes() {
      return QuoteRoutes
    },
    selectedViewComputed() {
      // return single column view
      if (!this.isAvailableItemsOpen) return this.selectedViewType
      return this.viewTypes.singleColView
    },
    totalQuoteItems() {
      return (
        this.calculateItemCategoryTotal(this.innerValue.quotingMethod, cloneDeep(this.innerValue.labours), ItemCategoryTypes.RR) +
        this.calculateItemCategoryTotal(this.innerValue.quotingMethod, cloneDeep(this.innerValue.labours), ItemCategoryTypes.REP) +
        this.calculateItemCategoryTotal(this.innerValue.quotingMethod, cloneDeep(this.innerValue.labours), ItemCategoryTypes.PAINT) +
        this.calculateItemCategoryTotal(this.innerValue.quotingMethod, cloneDeep(this.innerValue.others), ItemCategoryTypes.MECH) +
        this.calculateItemCategoryTotal(this.innerValue.quotingMethod, cloneDeep(this.innerValue.parts), ItemCategoryTypes.PART) +
        this.calculateItemCategoryTotal(this.innerValue.quotingMethod, cloneDeep(this.innerValue.miscs), ItemCategoryTypes.MISC) +
        this.calculateItemCategoryTotal(this.innerValue.quotingMethod, cloneDeep(this.innerValue.sublets), ItemCategoryTypes.SUBL)
      )
    }
  },
  watch: {
    selectedViewType(val) {
      // check if value matches any of the view types
      if (Object.values(this.viewTypes).includes(val)) {
        StoreUtil.setLocalStorage('quoteBuilder', 'selectedViewType', val)
      }
    },

    innerValue: {
      handler: _debounce(function (newVal) {
        if (newVal) {
          // compare the new value with value
          if (JSON.stringify(newVal) === JSON.stringify(this.value)) return
          console.log('handle update')
          this.handleUpdate(newVal)
        }
      }, 1000),
      deep: true
    },

    value: {
      handler: function (newValue, oldValue) {
        // compare the new value with inner value
        if (JSON.stringify(newValue) === JSON.stringify(this.innerValue)) return
        this.innerValue = cloneDeep(newValue)
      },
      deep: true
    }
  },
  created() {
    this.innerValue = cloneDeep(this.value)

    // get selected view type from local storage
    const selectedViewType = StoreUtil.getLocalStorage('quoteBuilder', 'selectedViewType')
    if (selectedViewType) {
      this.selectedViewType = selectedViewType
    }
  },
  methods: {
    handleSummaryClick() {
      this.$router.push({ name: this.routeTypes.QuoteSummary.name, params: { quoteId: this.$route.params.quoteId }, query: this.$route.query })
    },

    handleToggleAvaliableMainItems() {
      this.isAvailableItemsOpen = false
      this.isAvailableMainActive = !this.isAvailableMainActive
    },
    handleToggleAvaliableItems() {
      this.isAvailableMainActive = false
      this.isAvailableItemsOpen = !this.isAvailableItemsOpen
    },

    handleUpdate(value) {
      console.log('handleUpdate', value)
      this.$emit('input', value)
    },
    closePresentAsModal() {
      this.$nextTick(() => {
        this.isPresentAsModalActive = false
      })
    },
    handlePresentAs(itemId) {
      /// search for the item in the innerValue in parts, labour, misc, sublets
      const item = [...this.innerValue.parts, ...this.innerValue.labours, ...this.innerValue.others, ...this.innerValue.miscs, ...this.innerValue.sublets].find(
        (item) => item.quoteItemId === itemId
      )
      if (item) {
        this.item = item
        this.isPresentAsModalActive = true
      }
    }
  }
}
</script>

<style lang="scss" >
.full-quote-page-height {
  height: fit-content;
  // 52px for the navbar and 52px for the quote header, 20px for the padding
  min-height: calc(100vh - 52px - 52px - 20px) !important;
}

.cell {
  .title {
    font-size: 1.2em;
    font-weight: bold;
    margin-bottom: 10px;
    border-bottom: 2px solid rgb(6, 108, 224);
  }

  .row {
    background-color: #eeeeee;
    border: 1px solid #000000;
    padding: 10px;
    margin: 4px;
    border-radius: 5px;
  }
}
</style>

<!--     handleFocusEvent(evt) {
      function getTr(element) {
        if (element.nodeName.toLowerCase() == 'tr') {
          return element
        }

        return getTr(element.parentElement)
      }

      let getItemFromElement = (e) => {
        let tr = getTr(e)
        let itemType = tr.dataset.quoteItemType
        let quoteItemId = tr.dataset.quoteItemId
        let listName = this.determineMethodFromType(itemType).list
        let item = this.innerValue[listName].filter((r) => r.quoteItemId == quoteItemId)

        if (item.length != 1) {
          throw 'This shouldnt happen must have an existing item????'
        }
        item = item[0]
        return item
      }

      let typeTables = [...document.querySelectorAll('.item-type-table')]
      typeTables = typeTables.reduce((set, r) => {
        let sortIndex = r.dataset.sortIndex
        set[sortIndex] = {
          itemType: r.dataset.itemType,
          items: []
        }

        let focusTargets = [...r.querySelectorAll('.focus-target')]
        focusTargets = focusTargets.filter((e) => {
          let tr1 = getTr(e)
          let tr2 = getTr(tr1.parentElement)
          return !tr2.classList.contains('filtered-out')
        })
        focusTargets = focusTargets.sort((a, b) => {
          let aSortIndex = a.dataset.sortIndex
          let bSortIndex = b.dataset.sortIndex

          if (aSortIndex != bSortIndex) {
            return aSortIndex.localeCompare(bSortIndex)
          }

          let aR = a.getBoundingClientRect()
          let bR = b.getBoundingClientRect()

          if (aR.top != bR.top) {
            return aR.top > bR.top
          }

          return aR.left > bR.left
        })
        focusTargets = focusTargets.reduce((lines, row) => {
          let sortIndex = row.dataset.sortIndex
          if (lines[sortIndex] == undefined) {
            lines[sortIndex] = []
          }
          lines[sortIndex].push(row)
          return lines
        }, {})

        set[sortIndex].items = focusTargets

        return set
      }, {})
      let typeTablesKeys = Object.keys(typeTables)
      console.log('typeTables', typeTables)

      let currentElement = document.activeElement
      let currentSortIndex = currentElement.dataset.sortIndex
      if (currentSortIndex === undefined) {
        let firstTypeTable = typeTables[typeTablesKeys[0]]
        if (firstTypeTable.items.length == 0) {
          // CREATE LINE
          this.addBlankItem(firstTypeTable.itemType)
        } else {
          let lineKeys = Object.keys(firstTypeTable.items)
          let firstLine = firstTypeTable.items[lineKeys[0]]
          let firstElement = firstLine[0]

          let targetItem = getItemFromElement(firstElement)
          this.$nextTick(() => {
            this.toggleSubRow(targetItem, 'enterPress')
            this.$nextTick(() => {
              firstElement.focus()
            })
          })
        }
        evt.preventDefault()
        return false
      }

      let currentItem = getItemFromElement(currentElement)
      let lineIndex = currentElement.dataset.sortIndex
      let tableIndex = lineIndex.substr(0, 7)
      let fieldIndex = typeTables[tableIndex].items[lineIndex].indexOf(currentElement)
      let currentTable = typeTables[tableIndex]
      let currentLines = currentTable.items
      let currentLine = currentLines[lineIndex]
      let isEndOfLine = fieldIndex == currentLine.length - 1
      let isLastLine = Object.keys(currentLines).indexOf(lineIndex) == Object.keys(currentLines).length - 1

      if (isEndOfLine) {
        if (isLastLine) {
          if (!this.isBlankItem(currentItem)) {
            this.addBlankItem(currentTable.itemType)
            evt.preventDefault()
            return false
          }

          this.deleteItem(currentItem)

          let nextTable = (function () {
            let position = typeTablesKeys.indexOf(tableIndex)
            if (position == typeTablesKeys.length - 1) {
              return typeTables[typeTablesKeys[0]]
            } else {
              return typeTables[typeTablesKeys[position + 1]]
            }
          })()

          let nextTableItemKeys = Object.keys(nextTable.items)
          if (nextTableItemKeys.length > 0) {
            let targetElement = nextTable.items[nextTableItemKeys[0]][0]
            let targetItem = getItemFromElement(targetElement)
            this.$nextTick(() => {
              this.toggleSubRow(targetItem, 'enterPress')
              this.$nextTick(() => {
                targetElement.focus()
              })
            })
          } else {
            this.addBlankItem(nextTable.itemType)
          }
          evt.preventDefault()
          return false
        } else {
          // GOTO NEXT LINE
          let lineKeys = Object.keys(currentLines)
          let position = lineKeys.indexOf(lineIndex)
          let targetElement = currentLines[lineKeys[position + 1]][0]
          let targetItem = getItemFromElement(targetElement)
          this.$nextTick(() => {
            this.toggleSubRow(targetItem, 'enterPress')
            this.$nextTick(() => {
              targetElement.focus()
            })
          })
          evt.preventDefault()
          return false
        }
      } else {
        // NOT END OF LINE
        let targetElement = currentLine[fieldIndex + 1]
        targetElement.focus()
      }

      evt.preventDefault()
      return false
    }, -->